.btn-outline-promoted {
  @include button-outline-variant(#fff, $blue);
  text-shadow: none;
}

// scss-lint:disable QualifyingElement

//
// Base styles
//

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $input-btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-border-radius);
  @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    cursor: $cursor-disabled;
    opacity: .65;
    @include box-shadow(none);
  }

  &:active,
  &.active {
    background-image: none;
    @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}




// Remove all backgrounds
.btn-outline-primary {
  @include button-outline-variant($btn-primary-bg);
}
.btn-outline-secondary {
  @include button-outline-variant($btn-secondary-border);
}
.btn-outline-info {
  @include button-outline-variant($btn-info-bg);
}
.btn-outline-success {
  @include button-outline-variant($btn-success-bg);
}
.btn-outline-warning {
  @include button-outline-variant($btn-warning-bg);
}
.btn-outline-danger {
  @include button-outline-variant($btn-danger-bg);
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:focus,
  &:active {
    border-color: transparent;
  }
  @include hover {
    border-color: transparent;
  }
  @include hover-focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &:disabled {
    color: $btn-link-disabled-color;

    @include hover-focus {
      text-decoration: none;
    }
  }
}


//
// Button Sizes
//

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

%button {
  padding: 1rem 1.8rem;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  border: 0;
  display: inline-flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: all .2s ease;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 3px;
  letter-spacing: 1px;
  &:active,
  &:focus {
    outline: none;
  }
  i {
    transition: all .2s ease;
  }
}

.btn-yellow {
  @extend %button;
  background: #fff;
  color: $brand-primary;
  &:hover {
    background: $brand-primary;
    color: #fff;
    border-color: $brand-primary;
    i {
      transform: translateX(5px);
    }
  }
  &:active, 
  &:focus {
    background-color: $brand-primary;
  }
}

.btn-brown {
  @extend %button;
  background: $brand-primary;
  color: #fff;
  &:hover {
    background: darken($brand-primary, 5%);
    color: $brand-secondary;
    border-color: darken($brand-primary, 5%);
  }
  &:active, 
  &:focus {
    background-color: darken($brand-primary, 10%)
  }
}




.btn-blue-alt {
  
  &:hover {
    color: $blue;
  }
  &:active, 
  &:focus {
    color: $blue;
  }
}


.btn-pink {
  @extend %button;
  background: $pink;
  color: #fff!important;
  &:hover {
    background: darken($pink, 10%);
    border-color: darken($pink, 10%);
  }
  &:active, 
  &:focus {
    background-color: darken($pink, 15%);
    border-color: darken($pink, 15%)
  }
}
.btn-white {
  @extend %button;
  background: #fff;
  color: #222;
  
  &:hover {
    background: transparent;
    color: #fff;
    border-color: #fff;
  }
  &:active, 
  &:focus {
    background-color: darken($blue, 10%)
  }
}


.btn-square {
  @extend %button;
}




// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: $btn-block-spacing-y;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


.read-more {
  display: inline-block;
  position: relative;
  &__btn {
  	border: 1px solid $blue;
  	padding: .3125rem 1rem;
  	border-radius: 1.2rem;

  }
  &:after {
  		padding-left: 5px;
  		content: '\e802';
		font-family: bem-icons;
		font-style: normal;
		font-weight: 400;
		speak: none;
		display: inline-block;
		text-decoration: inherit;
		width: 1em;
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: 1em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transition: transform .2s ease;
	}
	&:hover {
		&:after {
			transform: translateX(5px);
		}
	}

}

.form-group {
  button {
    margin-top: 0;
  }
}
