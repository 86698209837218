// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #5A2031;
$brand-primary:         darken(green, 17%);

$brand-secondary: 		#F8C700;

$header: #000;
$blue: 					$brand-primary;
$pink:					$brand-secondary;

$body-bg:				darken(#f9f9f7, 3%); // #f0f0e7;
// $body-bg:       #f9f9f7;

$body-color:    rgba(0,0,0,0.7);


$link-color:     $brand-primary;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$link-hover-decoration: none;
$font-family-sans-serif: "Montserrat", Helvetica, Arial, sans-serif !default;
$font-family-serif:      "Playfair Display", "Times New Roman", Times, serif !default;

$btn-border-radius:              30rem !default;
$btn-border-radius-lg:           30rem !default;
$btn-border-radius-sm:           30rem !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 1000px,
  lg: 1002px,
  xl: 1140px
) !default;


$grid-gutter-width-base:     30px !default;
$grid-gutter-width-lg:     30px !default;
$grid-gutter-width-md:     30px !default;
$grid-gutter-width-sm:     30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-sm,
  sm: $grid-gutter-width-sm,
  md: $grid-gutter-width-md,
  lg: $grid-gutter-width-lg,
  xl: $grid-gutter-width-base
) !default;



$border-radius:          2px !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;