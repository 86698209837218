.fs-30 {
	font-size: 40px;
}

%serif {
	font-family: $font-family-serif;
	font-weight: 900;
	
}
body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5 {
	line-height: 1.2;
	@extend %serif;
	font-variant-numeric: lining-nums;
    font-feature-settings: "lnum" 1;
}


.section-header {
	margin: 0 0 3rem;
	text-align: center;
	font-size: 30px;
	font-style: italic;
}

.page-panel {
	margin: 2rem 0 3rem;
	background: url(../images/blur.jpg) center center no-repeat;
	background-size: cover;
	h1 {
		font-size: 30px;
		padding: 2rem 0;
		font-weight: 600;
		color: #fff;
		font-style: italic;
		margin: 0;
		text-align: center;
		@extend %serif;
		@include media-breakpoint-up(md) { 
			text-align: left;
			font-size: 40px;
			padding: 3rem 0;
		}
	}
}

.page-header {
	margin-bottom: 2rem;
	font-weight: 300;
	font-size: 30px;
}



.module {
	margin-bottom: 3rem;
}

.txt-label {
	display: block;
}

.mt-2 {
	margin-top: 1rem;
}


.list-check {
	list-style: none;
	padding: 0;
	&>li {
		padding-left: 20px;
		&:before {
			content: '\e800';
			font-family: bem-icons;
			float: left;
			margin-left: -20px;
			display: inline-block;
		}
	}
}
:root {

  --blue: $blue;
  --primary: $body-bg;
}

.round-image {
	border-radius: 50%;
}


.mobile-nav {
	&__container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&>a {
			color: $blue;
		}
	}

}



%image-fluid {
	max-width: 100%;
	height: auto;
}


.section-title {
	margin-bottom: 2rem;
	color: $brand-primary;
}
.section-module	{
	margin: 0 0 2rem;
	padding: 1rem 0;
	& + .section-row {
		margin-top: -2rem;
	}
}

.section-row {
	background-color: rgba(0,0,0,.05);
	padding: 4rem 0;
	margin: 0 0 2rem 0;
	i {
		opacity: .4;
	}
	&__content {
		text-align: center;
		p {
			margin: 0;
		}
	}
}

.section-primary {
	color: #fff;
	background-color: $brand-primary;
	text-align: center;
	line-height: 2.5;
	font-size: 16px;

	@include media-breakpoint-up(md) { 
		font-size: 30px;
	}
	.btn-yellow {
		margin: -2px 1.5rem 0 1.5rem;
	}
	p {
		margin: 0;
	}
}




.teaser-xl {
	display: flex;
	flex-direction: column;
	&:nth-child(even) {
	
		
	}
	&__img {
		@include media-breakpoint-up(md) { 
			flex: 0 0 50%;
		}
		
		img {
			margin-right: 0;
			margin-left: auto;
			@extend %image-fluid;

		}
	}
	&__content {
		@include media-breakpoint-up(md) { 
			flex: 0 0 50%;
		}
		display: flex;
		align-content: center;
		justify-content: center;
		flex-direction: column;
	}
	&+ .teaser-xl {
		margin-top: 2rem;
	}	
}




.widget {
	
	border: 1px solid rgba(0,0,0,.05);
	padding: 1.5rem;
	text-align: center;
	margin: 0 0 2rem;
	border-radius: 3px;
	&__header {
		@extend .page-header;
		font-size: 20px;
		font-style: italic;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}
	&__content {
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-size: .9rem;
	}

}

.widget__primary {
	
	background-color: $brand-primary;
	color: rgba(255,255,255,.7);
	h3 {
		color: #fff;
	}
}


.list-products {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	&__item {
		flex: 0 1 auto;
		display: flex;
		
		margin: 0 0 30px;
	}

	&__link {
		display: flex;
		flex-direction: row;
		align-content: center;
		justify-content: center;
		min-height: 130px;
		width: 100%;
		color: $brand-primary;
		transition: background-color .3s ease;
		background-color: $brand-primary;
		padding: 1rem;
		flex: 0 0 100%;
		align-items: center;
		justify-content: center;
		position: relative;
		
		
		&:hover {
			background-color: darken($brand-primary, 5%);
		}
	}
	
	

	&__header {
		font-family: $font-family-sans-serif;
		text-align: center;
		color: #fff;
		margin: 0;
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			content: '\e80b';
			font-family: bem-icons;
			font-weight: normal;
			text-align: center;
			
			color: rgba(255,255,255,.2);
			font-size: .8rem;
			bottom: -2rem;
		}
	}	
}

.list-nav {
	margin: 0 0 2rem 0;
	list-style: none;
	padding: 0;
	&__item {
		border-bottom: 1px solid rgba(0,0,0,.1);
		
		a {
			display: block;
			padding: .3125rem;
		}

	}
	.current {
		
			font-weight: 600;
		
	}

}
.summary {
	font-size: 1.5rem;
	font-weight: 600;
}


@include media-breakpoint-up(lg) { 
	.teaser-xl {
		flex-direction: row;
		&:nth-child(even) {
			flex-direction: row-reverse;
			.teaser-xl__img {
				text-align: left;
			}
		}
		&__content {
			padding: 0 2rem;
		}
		&__img {
			text-align: right;
		}
	}
}
@include media-breakpoint-up(lg) { 
	.news-list {
		&__item {
			&--header {
				font-size: 2rem;		
			}			
		}
	}

	.module__header {
		font-size: 2rem;
	}
	
	.list-products__item {
		min-height: 200px;
	}

}

.list-fff {
	.teaser__link { 

		background-color: rgba(0,0,0,.05);
		padding: 15px;
		transition: background-color .2s ease;
		&:hover {
			background-color: rgba(0,0,0,.07);
		}

	}
}

.teaser {
	margin: 0;
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	text-align: center;
	p {
		
		line-height: 1.25;
	}
	&__header {
		color: $header;
		font-weight: 600;
		margin-bottom: .6em;

	}
	&__link {
		display: flex;
		flex-direction: column;
		flex-grow: 2;
		&:hover {
			img {
				opacity: .9;
			}
		}
	}
	
	&__image {
		img {
			@extend %image-fluid;
			transition: opacity .2s ease;
			backface-visibility: hidden; 
		}
	}

	&__content {
		flex: 2 0 auto;
		font-size: 16px;
	}

	&__cta {
		flex: 0 0 auto;
	}	
}


.teaser-grid {
	margin-left: -15px;
	margin-right: -15px;
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	
	&>li {
		margin-bottom: 50px;
		flex: 0 0 100%;
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
	}
	p {
		color: #666;
	}	
}

.teaser-grid__2 {
	@include media-breakpoint-up(md) { 
		&>li {
			flex-basis:50%;
		}
	}
}

.teaser-grid__3 {
	@include media-breakpoint-up(md) { 
		&>li {
			flex-basis: 33.3333333%;
		}
	}
}

.faq {
	&__header {
		font-family: $font-family-sans-serif;
		font-size: 21px;
		margin: 0;
		padding: 10px 0;
		font-weight: 600;
		position: relative;
		cursor: pointer;
		&:after {
			content: '+';
			transition: transform .2s ease;
			transform-origin: center center;
			float: right;
			font-size: 2rem;		
			line-height: .8;
		}
	}
	&__answer {
		overflow: hidden;
		max-height: 0em;
		// transition: max-height .4s ease;
		/*padding-right: 50px;*/
		p:last-child {
			margin-bottom: 0;
		}
	}
}


.list-faq {
	list-style: none;
	padding: 0;
	border-top: 1px solid rgba(0,0,0,.1);

	&__item {
		padding-left: 50px;
		border-bottom: 1px solid rgba(0,0,0,.1);
		padding: 10px 0;
		margin: 0;
		position: relative;

		&.open {
			.faq__header {
				&:after {
					transform: rotate(-45deg);
				}
			}
			
		}
	}
}

.open {
	.faq__answer {
		max-height: 9999px;
     	animation: faq 0.2s normal ease-in-out both 1; 
	}	
}

@keyframes faq {
	0% {
	    opacity: 0;
	    transform:  translateY(-10px);
	    transform-origin: 50% 0;
	}
	
	100% {
	    opacity: 1;
	    transform:  translateY(0);
	}	
}




.acf-map {
	width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 20px 0;
}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}

.teaser-staff {
	padding: 0;
	list-style: none;
	li {
		margin-bottom: 1rem;
		overflow: hidden;
	}
	&__figure {
		margin: 0;
		padding: 3px; 
		background-color: #fff;
		float: left;
		max-width: 130px;
		img {
			@extend %image-fluid;
		}
	}
	&__content {
		padding-left: 1rem;
		overflow: hidden;
		font-size: 16px;
	}
}


.section-quote {
	padding: 8rem 0;
	background-size: cover;
	background-position: center center;
	position: relative;
	margin-bottom: 3rem;
	&:before {
		content: '';
		background-color: $brand-primary;
		opacity: .6;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
	}
	&__txt {
		z-index: 2;
		font-family: $font-family-serif;
		font-size: 40px;
		color: #fff;
		text-align: center;
		font-weight: 400;
		line-height: 1.4;
		@include media-breakpoint-down(sm) { 
			
			font-size: 30px;
		}
	}

}

.entry-content {
	ul { 
	margin-bottom: 20px
 }
	li {
		margin-bottom: 10px;
	}
}