@mixin fontFace($family,$src,$style: normal,$weight: normal) {
	@font-face {
		font-family: $family;
		
		src: 
			url('#{$src}.woff') format('woff'), 
			url('#{$src}.woff') format('woff2'); 

		font-style: $style;
		font-weight: $weight;
	}
}


// Import fonts

@include fontFace('bem-icons', '../fonts/bem-icons', 'normal',400);

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "bem-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-check:before { content: '\e800'; } /* '' */
.icon-right-open:before { content: '\e801'; } /* '' */
.icon-right:before { content: '\e802'; } /* '' */
.icon-left-open:before { content: '\e803'; } /* '' */
.icon-down-open:before { content: '\e804'; } /* '' */
.icon-up-open:before { content: '\e805'; } /* '' */
.icon-search:before { content: '\e806'; } /* '' */
.icon-location:before { content: '\e807'; } /* '' */
.icon-clock:before { content: '\e808'; } /* '' */
.icon-mail:before { content: '\e809'; } /* '' */
.icon-phone:before { content: '\e80a'; } /* '' */
.icon-ornament:before { content: '\e80b'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */