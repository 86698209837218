.hero {
	position: relative;
	height: 50vh;
	max-height: 400px;
	overflow: hidden;
	margin-bottom: 2rem;
	@include media-breakpoint-up(lg) { 
		max-height: 600px;	
	}
}
.fullscreen-bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	align-self: center;
	justify-content: center;
	&__content {
		position: relative;
		z-index: 15;
		align-self: center;
		max-width: 800px;
		text-align: center;
		
		h1 {
			color: #fff;
			padding: 0 1rem;
			margin: 0;
			font-size: 35px;
			font-weight: 700;
			line-height: 1.2;
			text-shadow: 0 0 30px rgba(0,0,0,.75);

			@include media-breakpoint-up(md) { 
				font-size: 70px;
				font-style: italic;
				text-shadow: 0 0 30px rgba(0,0,0,.4);
				margin: 0 0 1rem;
			}
		}

		

			
	}
	
  &__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		height: 300%;
		top: -100%;
  }
}

@include media-breakpoint-down(sm) { 
  

  .fullscreen-bg__video {
    display: none;
  }
}


