
%transition { transition: all .1s ease; }



// field structure
.field {
	position: relative;
	margin-bottom: .625rem;
	
	&__input, 
	textarea {		
		@extend %transition;
		padding:  .5rem 1rem;
		line-height: 2rem;
		width: 100%;
		background-color: #fff;
		border: 0;
		outline: 1px solid rgba(0,0,0,.1);
		color: rgba(0,0,0,.9);
		font-family: $font-family-sans-serif;

		&:focus {
			
			outline: 1px $brand-secondary solid;
			~label {
				top: 5px;
				font-size: 10px;
			}
		}
	}

}

textarea {
	height: 150px;
}

.input {
	outline: 0;
	border: 1px solid #ccc;
	

	&:focus {
		background-color: white;
		border-color: #4CAF58;
		&  label {
			color: red;
		}
	}
	
	&--text {	}
	&--textarea {
		max-width: 100%;
		height: 100px;
		max-height: 100px;
	}
	&--select {
		padding-right: 20px;
	}
	&--checkbox {}
	&--radio {}
	&--disabled {}	
}

// specific label styles
.label {
	&--required {
		&:after {
			content:"*";
			color: tomato;
			margin-left: 5px;
		}
	}
}


.site-footer {
	// field structure
	.field {
		&__input, 
		textarea {		
			
			padding:  0 10px;
			line-height: 2.8rem;
			width: 100%;
			background-color: rgba(0,0,0,.1);
			border: 0;
			outline: 1px solid rgba(255,255,255,.1);
			color: #fff;

			&:focus {
				
				outline: 1px $brand-secondary solid;
				~label {
					top: 5px;
					font-size: 10px;
				}
			}
		}

	}


	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: rgba(255,255,255,.7);
	}

	::-moz-placeholder { /* Firefox 19+ */
	  color: #fff;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: #fff;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: #fff;
	}


}