.site-footer {
	margin: 3rem 0 0 0;
	padding: 0 0 3rem 0;
	background-color: $brand-primary;
	background-image: radial-gradient(50px at 50% 100px, rgba(0,0,0,.4), transparent 75vw—);
	border-top: 1px solid $brand-primary;
	color: rgba(255,255,255,.9);
	font-size: .9rem; 
	a {
		color: $brand-secondary;
		transition: color .2s ease; 
		&:hover {
			text-decoration: underline;
			color: darken($brand-secondary, 5%);
		}
	}
	h4 {
		font-weight: 300;
		font-size: 20px;
		margin-bottom: 1.5rem;
	}
	p {
		color: rgba(255,255,255,.8);
		font-size: .9rem; 
	}
	&__top {
		padding: 2rem 0;
	}
	&__logo {
		width: 230px;
		margin: 0 auto;
		&--text {
			display: none;
		}
	}

	.divider {
		height: 1px;
		margin-bottom: 2rem;
		background-color: rgba(255,255,255,.1);
	}
}

.footer-nav {
	list-style: none;
	padding: 0;
	&> li  {
		margin: 0;
		border-bottom: 1px solid rgba(255,255,255,.1);
		a {
			display: block;
			padding: .625rem 0;

		}
	}
}


@include media-breakpoint-up(md) { 
	.site-footer {
		background-image: radial-gradient(50px at 50% 0, rgba(0,0,0,.4) 22px, transparent 35vw);
	}
}