

.toggle-menu {
	width: 26px;
	height: 22px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
	span {
		display: inline-block;
		height: 3px;
		background-color: $blue;
	}
	
}

.mobile-nav__container {
	.toggle-menu:after {
		content: 'MENU';
		display: inline-block;
		position: relative;
		margin-bottom: -20px;
		right: 0;
		font-size: 10px;
		font-weight: 700;
	}
}

.mobile-nav__container {
	position: absolute;
	right: 1rem;
	top: 0;
	height: 100%;
}



.header-content {
	display: flex;
	
	@include media-breakpoint-up(md) {
		margin: 2rem 0;
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-content: flex-start;
	}
	&__logo {
		flex: 1 0 auto;
		align-items: center;
		display: flex;


		@include media-breakpoint-up(md) {
			flex: 0 0 39%;
			margin-bottom: 10px;
		}
	}
	&__address {
		display: none;

		@include media-breakpoint-up(md) {
			display: block;
			flex: 1 0 20%;	
			text-align: left;
			color: $brand-primary;
			line-height: 1.3;
			font-size: 1rem;
			p {
				margin-bottom: 0;
				font-size: 14px;
			}
			a {
				text-decoration: underline;
			}
		}
	}
}



.logo {
	width: calc(100% - 80px);
	max-width: 200px;
	position: relative;
	height: 7rem;
	background: url(../images/elving.svg) center center no-repeat;
	background-size: 90% auto;
	margin: 0 auto;
	display: block;
	&--text {
		display: none;
	}
}


.active {
	.mobile-arrow i:before {
		transform: rotate(90deg);
	}
	.sub-menu {
		max-height: 9999px;
		visibility: visible;
	}
}

.main-nav__menu {
	display: flex;
	position: fixed;
	align-items: flex-start;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	max-width: 280px;
	width: 100%;
	background-color: $brand-primary;
	transform: translateX(-100%);
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease;

}
.wrap {
	position: relative;
	left: 0;
	transition: left .2s cubic-bezier(0.820, 0.070, 0.750, 1.035);
}
@include media-breakpoint-down(sm) { 
	.show-mobile-nav {
		.wrap {
			left: 100px;
		}
		.main-nav {

			flex-direction: row-reverse;
			&__menu {
				transform: translateX(0);
				z-index: 20;
				visibility: visible;
				opacity: 1;
				transition: all .3s cubic-bezier(0.385, 0.155, 0.220, 1.015);
				box-shadow: 2px 0 2px rgba(0,0,0,.4);
			}
		}

		
		.nav {
			margin: 5rem 0 0;
			a {
				padding: 1rem 0;
				line-height: 1;
				display: block;
				color: #fff;
				font-size: 30px;
			}
		}
		.fullscreen__close {
			display: block;
			position: absolute;
			top: 2.8rem;
			right: 1rem;
			span {
				width: 30px;
				height: 30px;
				display: inline-block;
				transform: translateX(0);
				&:before,
				&:after {
					height: 1px;
					width: 100%;
					background-color: #fff;
					display: inline-block;
					content: '';
					position: relative;
					-webkit-transition: .2s ease;
					transition: .2s ease;
					position: absolute;
					top: 50%;
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.main-nav {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 7rem;

	@include media-breakpoint-up(md) { 
		flex-direction: row;
		height: auto;
	}

	&__logo {
		text-align: center;

	}
	&__menu {

	}
}


.nav-primary {
	width: 100%;
	padding: 1rem;

}
.menu-primary-navigation-container {
	width: 100%;
}
.nav {
	align-items: flex-start;
	justify-content: flex-start;

	display: flex;
	padding: 0;
	list-style: none;
	flex-direction: column;
	&>li {
		width: 100%;
		border-bottom: 1px solid rgba(255,255,255,.4);
		text-align: left;
	}
	@include media-breakpoint-up(md) { 
		flex-direction: row;
	}
}

@include media-breakpoint-up(md) { 
	.fullscreen__close {
		display: none;
	}
	.nav-primary {
		padding: 0;
	}
	.main-nav__menu  {
		display: block;
		position: relative;
		transform: none;
		max-width: none;
		opacity: 1;
		visibility: visible;
		&:before,
		&:after {
			background-color: $brand-primary;
			height: 3rem;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			content: '';
			transform: translateX(-100%);
		}
		&:after {
			/*
			left: auto;
			right: 0;*/
			transform: translateX(100%);
			z-index: -1;
		}
	}
	
	.menu-primary-navigation-container {
		background-color: $brand-primary;
		height: 3rem;
		position: relative;
		
	}
	.nav { 
		display: flex; 
		margin: 0;
		align-items: flex-start;
		justify-content: center;
		li {
			width: auto;
			// flex: 2 0 auto;
			border: 0;
			font-size: .9rem;
			letter-spacing: .5px;
			font-weight: 600;

		}
		a {
			color: #fff;
			display: block;
			line-height: 3rem;
			padding: 0 2rem;
			transition: all .2s ease;
			&:hover {
				color: $brand-secondary;
			}
		}
		.active {
			a {
				color: $brand-secondary;
			}
		}
	}
	.mobile-nav__container {
		display: none;
	}
	.logo {
		margin: 0;
		width: calc(100% - 80px);
		max-width: 250px;
		height: 4.5rem;
		background-position: left center;
	}
}

